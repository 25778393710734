import React, { useEffect, useState } from 'react';
import { HTTP } from '../../lib/http';
import { EditableTable } from '../table';

export function WebTokens() {
  const [rowData, setRowData] = useState([]);

  const columnDef = [
    { field: 'level', filter: 'agNumberColumnFilter', checkboxSelection: true },
    { field: 'name', filter: 'agTextColumnFilter' },
    { field: 'token', filter: 'agTextColumnFilter' },
  ];

  async function getData() {
    const data = await HTTP.getWebTokens();
    setRowData(data);
  }

  useEffect(() => {
    getData();
  }, []);

  function onGridReady({ columnApi }) {
    columnApi.applyColumnState({
      state: [{ colId: 'level', sort: 'desc' }],
    });
  }

  return (
    <EditableTable
      columnDef={columnDef}
      deleteRowFn={(row) => {
        HTTP.executeCommand(`webtokens remove ${row.name}`);
      }}
      reloadFn={getData}
      rowData={rowData}
      onGridReady={onGridReady}
    />
  );
}
