import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useAuth } from '../components/auth/index';

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  text-align: left;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PermissionCard = styled.div`
  padding: 1rem;
`;

const PermissionUl = styled.ul`
  list-style-type: none;
  padding-left: 1rem;
`;

function ModulePermissionCard({ modulePermission }) {
  const parts = Object.keys(modulePermission.allowed);

  return (
    <PermissionCard>
      <h3>{modulePermission.module}</h3>
      <PermissionUl>
        {parts.map((part) => {
          return (
            <li key={part}>
              {modulePermission.allowed[part] ? '🟢' : '🔴'} {part}
            </li>
          );
        })}
      </PermissionUl>
    </PermissionCard>
  );
}

ModulePermissionCard.propTypes = {
  modulePermission: PropTypes.object.isRequired,
};

export function Profile() {
  const { status } = useAuth();

  const modulePermissionComponents = status.permissions.map((modulePermission) => {
    return <ModulePermissionCard key={modulePermission.module} modulePermission={modulePermission} />;
  });

  return (
    <Container>
      <h1>Profile</h1>

      <h2>{status.username}</h2>
      <small>Permission level: {status.permissionLevel}</small>

      <h2>Permissions</h2>
      <CardsContainer>{modulePermissionComponents}</CardsContainer>
    </Container>
  );
}
