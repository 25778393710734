import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components/macro';

const AccordionTitle = styled.div`
  font-size: 1, 5rem;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1rem;
  background-color: ${(props) => props.theme.colors.tfpDarkGrey40};
  color: ${(props) => (props.open ? props.theme.colors.tfpRed : props.theme.colors.tfpWhite)};
  border-radius: 5px;
  padding: 0% 5%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AccordionItemContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.open ? '80%' : '5%')};
  margin-top: 1rem;
`;

const AccordionChildrenContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-right: 3rem;
`;

const AccordionItemComponentContainer = styled.div`
  width: 100%;
  height: 90%;
`;

export function Accordion({ items }) {
  const [openKey, setOpenKey] = useState();

  const handleToggle = (key) => {
    setOpenKey(openKey !== key ? key : null);
  };

  return (
    <AccordionChildrenContainer>
      {items.map(({ title, component }) => (
        <AccordionItem key={title} title={title} component={component} toggle={handleToggle} open={openKey === title} />
      ))}
    </AccordionChildrenContainer>
  );
}

function AccordionItem({ component, title, open, toggle }) {
  return (
    <AccordionItemContainer open={open}>
      <AccordionTitle open={open} onClick={() => toggle(title)}>
        <h3>{title}</h3>
      </AccordionTitle>
      {open && <AccordionItemComponentContainer>{component}</AccordionItemComponentContainer>}
    </AccordionItemContainer>
  );
}

AccordionItem.propTypes = {
  component: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    })
  ).isRequired,
};
