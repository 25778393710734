import { createContext } from 'react';
import { useEffect, useState, useContext } from 'react';
import { HTTP } from '../../lib/http';
import PropTypes from 'prop-types';

export const PERMISSIONS = {
  'webevent.log': 'webevent.log',
  'webapi.Map': 'webapi.Map',
  'webapi.Animal': 'webapi.Animal',
  'webapi.Command': 'webapi.Command',
  'webapi.Hostile': 'webapi.Hostile',
  'webapi.Log': 'webapi.Log',
  'webapi.Player': 'webapi.Player',
  'webapi.RegisterUser': 'webapi.RegisterUser',
  'webapi.ServerInfo': 'webapi.ServerInfo',
  'webapi.ServerStats': 'webapi.ServerStats',
  'webapi.Mods': 'webapi.Mods',
  'webapi.WebUiUpdates': 'webapi.WebUiUpdates',
  'webapi.viewallclaims': 'webapi.viewallclaims',
  'webapi.viewallplayers': 'webapi.viewallplayers',
  'web.map': 'web.map',
};

async function getStatus() {
  return HTTP.get('/userstatus');
}

export const AuthContext = createContext({ status: { loggedin: false } });

export function AuthProvider({ children }) {
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);

  function checkPermission(requestedPermissions) {
    const permissionsToCheck = Array.isArray(requestedPermissions) ? requestedPermissions : [requestedPermissions];
    for (let i = 0; i < permissionsToCheck.length; i++) {
      const mod = status.permissions.find((p) => p.module.toLowerCase() === permissionsToCheck[i].module.toLowerCase());
      if (!mod || !mod.allowed[permissionsToCheck[i].method]) {
        return false;
      }
    }

    return true;
  }

  async function refreshStatus() {
    const status = await getStatus();
    setStatus(status);
    setPermissions(status.permissions);
    setLoading(false);
  }

  useEffect(() => {
    refreshStatus();
  }, []);

  if (loading) return null;

  return <AuthContext.Provider value={{ status, loading, permissions, checkPermission, refreshStatus }}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  return useContext(AuthContext);
}
