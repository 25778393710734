import styled from 'styled-components/macro';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { NotFound } from './pages/404';
import { Settings } from './pages/Settings';
import { Error } from './pages/Error';
import { Register } from './pages/Register';
import { Console } from './pages/Console';
import { Mods } from './pages/Mods';
import { Map } from './pages/Map';

import { useMods } from './lib/mods';
import { Profile } from './pages/Profile';
import { Empty } from './pages/Empty';

const ContentContainer = styled.div`
  min-width: ${(props) => (props.collapsed ? 'calc(100% - 100px)' : 'calc(100% - 250px)')};
  padding: 1%;
`;

export function TFPRouter({ sidebarCollapsed }) {
  const { modRoutes } = useMods();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Empty />
          </ContentContainer>
        }
      />
      <Route
        path="/map"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Map />
          </ContentContainer>
        }
      />
      <Route
        path="mods"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Mods />
          </ContentContainer>
        }
      />
      <Route
        path="console"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Console />
          </ContentContainer>
        }
      />
      <Route
        path="settings"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Settings />
          </ContentContainer>
        }
      />

      <Route
        path="profile"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <Profile />
          </ContentContainer>
        }
      />

      <Route path="createuser" element={<Register />} />

      {modRoutes.map((route) => {
        return (
          <Route
            path={route.path}
            element={<ContentContainer collapsed={sidebarCollapsed}>{route.element}</ContentContainer>}
            key={route.path}
          />
        );
      })}

      <Route path="error/:code" element={<Error />} />

      <Route
        path="*"
        element={
          <ContentContainer collapsed={sidebarCollapsed}>
            <NotFound />
          </ContentContainer>
        }
      />
    </Routes>
  );
}

TFPRouter.propTypes = {
  sidebarCollapsed: PropTypes.bool.isRequired,
};
