import React, { useEffect, useState } from 'react';
import { HTTP } from '../../lib/http';
import { EditableTable } from '../table';

export function WebPermissions() {
  const [rowData, setRowData] = useState([]);

  const columnDef = [
    { field: 'level', filter: 'agNumberColumnFilter', checkboxSelection: true },
    { field: 'permission', filter: 'agTextColumnFilter' },
  ];

  async function getData() {
    const data = await HTTP.getWebPermissions();
    setRowData(data);
  }

  useEffect(() => {
    getData();
  }, []);

  function onGridReady({ columnApi }) {
    columnApi.applyColumnState({
      state: [{ colId: 'permission', sort: 'asc' }],
    });
  }

  return (
    <>
      <EditableTable
        columnDef={columnDef}
        deleteRowFn={(row) => {
          HTTP.executeCommand(`webpermission remove ${row.permission}`);
        }}
        reloadFn={getData}
        rowData={rowData}
        onGridReady={onGridReady}
      />
    </>
  );
}
