import styled, { useTheme } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { CollapseIcon } from '../collapseIndicator';
import { FaComment, FaExclamationTriangle, FaBug, FaTerminal, FaExclamationCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

const COLLAPSED_LENGTH = 125;

const StyledConsoleLineContainer = styled.div`
  cursor: ${(props) => (props.canCollapse ? 'pointer' : 'default')};
`;

const StyledTimestamp = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  white-space: pre;
`;

const StyledConsoleLine = styled.div`
  max-width: 100%;
  vertical-align: top;
  display: flex;
  white-space: ${(props) => (props.collapsed ? 'pre' : 'pre-wrap')};
  text-align: left;
  margin: 0;
  text-overflow: ${(props) => (props.collapsed ? 'ellipsis' : '')};
  overflow: hidden;
`;

const StyledConsoleLineBody = styled.div`
  text-overflow: inherit;
  overflow: inherit;
`;

const StyledConsoleLineHeader = styled.div`
  text-overflow: inherit;
  overflow: inherit;
`;

const StyledConsoleLineCommandInput = styled.strong`
  text-overflow: inherit;
  overflow: inherit;
  color: ${(props) => props.theme.colors.tfpBlue};
`;

function ConsoleHeader({ message }) {
  const { header } = splitMessage(message);
  return <StyledConsoleLineHeader>{header}</StyledConsoleLineHeader>;
}

function ConsoleBody({ message }) {
  const { header, body } = splitMessage(message);
  return (
    <StyledConsoleLineBody>
      <StyledConsoleLineHeader>{header}</StyledConsoleLineHeader>
      <p>{body}</p>
    </StyledConsoleLineBody>
  );
}

function shouldCollapseByDefault(text) {
  if (!text) return false;

  if (text.type === 'executedCommand') {
    return true;
  }

  if (text.trace) {
    return true;
  }

  return text.msg.length > COLLAPSED_LENGTH;
}

function splitMessage(message) {
  if (message.type === 'executedCommand') {
    return {
      header: <StyledConsoleLineCommandInput>{message.input}</StyledConsoleLineCommandInput>,
      body: message.output,
    };
  }
  if (message.trace) {
    return {
      header: message.msg,
      body: message.trace,
    };
  }

  const header = message.msg.split('\n')[0];

  const messageWithTrace = message.msg + '\n' + message.trace;

  const body = messageWithTrace.split('\n').slice(1).join('\n');

  return {
    header,
    body,
  };
}

function getIcon(type, index, theme) {
  switch (type) {
    case 'Log':
      return (
        <>
          <FaComment
            style={{ maxWidth: '14px', minWidth: '14px' }}
            data-tip="React-tooltip"
            data-for="tooltip-log"
            key={`console-type-icon-${index}`}
          />
          <ReactTooltip id="tooltip-log" key={`console-type-tooltip-${index}`}>
            Log
          </ReactTooltip>
        </>
      );
    case 'Warning':
      return (
        <>
          <FaExclamationTriangle
            style={{ maxWidth: '14px', minWidth: '14px' }}
            color="yellow"
            data-tip="React-tooltip"
            data-for="tooltip-warning"
            key={`console-type-icon-${index}`}
          />
          <ReactTooltip id="tooltip-warning" key={`console-type-tooltip-${index}`}>
            Warning
          </ReactTooltip>
        </>
      );
    case 'Exception':
      return (
        <>
          <FaBug
            style={{ maxWidth: '14px', minWidth: '14px' }}
            color="red"
            data-tip="React-tooltip"
            data-for="tooltip-exception"
            key={`console-type-icon-${index}`}
          />
          <ReactTooltip id="tooltip-exception" key={`console-type-tooltip-${index}`}>
            Exception
          </ReactTooltip>
        </>
      );
    case 'Error':
      return (
        <>
          <FaExclamationCircle
            style={{ maxWidth: '14px', minWidth: '14px' }}
            color="red"
            data-tip="React-tooltip"
            data-for="tooltip-error"
            key={`console-type-icon-${index}`}
          />
          <ReactTooltip id="tooltip-error" key={`console-type-tooltip-${index}`}>
            Error
          </ReactTooltip>
        </>
      );
    case 'Assert':
      return (
        <>
          <FaExclamationCircle
            style={{ maxWidth: '14px', minWidth: '14px' }}
            color="red"
            data-tip="React-tooltip"
            data-for="tooltip-assert"
            key={`console-type-icon-${index}`}
          />
          <ReactTooltip id="tooltip-assert" key={`console-type-tooltip-${index}`}>
            Assert
          </ReactTooltip>
        </>
      );
    case 'executedCommand':
      return (
        <>
          <FaTerminal
            style={{ maxWidth: '14px', minWidth: '14px' }}
            color={theme.colors.tfpBlue}
            data-tip="React-tooltip"
            data-for="tooltip-input"
            key={`console-type-icon-${index}`}
          />
          <ReactTooltip id="tooltip-input" key={`console-type-tooltip-${index}`}>
            Executed command
          </ReactTooltip>
        </>
      );
    default:
      return <FaComment />;
  }
}

export function ConsoleLine({ message }) {
  const bottomRef = useRef(null);
  const canCollapse = shouldCollapseByDefault(message);
  const [collapsed, setCollapsed] = useState(message.startCollapsed ?? true);
  const theme = useTheme();

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [collapsed]);

  function onClick(e) {
    if (canCollapse) {
      const selection = window.getSelection();
      if (selection.type !== 'Range') {
        setCollapsed(!collapsed);
      }
    }
  }
  return (
    <StyledConsoleLineContainer onClick={onClick} canCollapse={canCollapse}>
      <StyledConsoleLine ref={bottomRef} collapsed={collapsed}>
        {getIcon(message.type, `${message.timestamp}-${message.msg}`, theme)}
        <StyledTimestamp>{new Date(message.timestamp).toLocaleString()}</StyledTimestamp>
        {collapsed ? <ConsoleHeader message={message} /> : <ConsoleBody message={message} />}
        <CollapseIcon canCollapse={canCollapse} collapsed={collapsed} />
      </StyledConsoleLine>
    </StyledConsoleLineContainer>
  );
}

ConsoleLine.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    timestamp: PropTypes.instanceOf(Date),
    msg: PropTypes.string,
    trace: PropTypes.string,
    startCollapsed: PropTypes.bool,
  }),
};

ConsoleHeader.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    timestamp: PropTypes.instanceOf(Date),
    msg: PropTypes.string,
    trace: PropTypes.string,
  }),
};

ConsoleBody.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    timestamp: PropTypes.instanceOf(Date),
    msg: PropTypes.string,
    trace: PropTypes.string,
  }),
};
