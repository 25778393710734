import { useEffect, useState } from 'react';
import { useMap, LayerGroup, LayersControl, Marker } from 'react-leaflet';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { hydrateModComponent, useMods } from '../../lib/mods';
import { useAuth } from '../auth';
import { HideBasedOnAuth } from '../auth/hideBasedOnAuth';

export function MapMods() {
  const { webMods } = useMods();
  const [modComponents, setModComponents] = useState([]);
  const { checkPermission } = useAuth();

  useEffect(() => {
    if (webMods) {
      const modComponents = webMods
        .filter((m) => m.external.mapComponents && m.external.mapComponents.length)
        .map((mod) => {
          return <GenericModComponent key={mod.name} modComponent={mod.external.mapComponents[0]} checkPermission={checkPermission} />;
        });
      setModComponents(modComponents);
    }
  }, [JSON.stringify(webMods)]);

  return modComponents;
}

function GenericModComponent({ modComponent, checkPermission }) {
  const map = useMap();
  return hydrateModComponent(modComponent, { map, checkPermission, LayerGroup, LayersControl, Marker, HideBasedOnAuth, L });
}

GenericModComponent.propTypes = {
  modComponent: PropTypes.func.isRequired,
};
