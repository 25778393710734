import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { Table } from '../table';
import { HTTP } from '../../lib/http';
import { debounce } from '../../lib/debounce';
import PropTypes from 'prop-types';
import { ConsoleHelpTableCell } from './ConsoleHelpTableCell';
import useLocalStorageState from 'use-local-storage-state';

const HelpWindow = styled.div`
  color: ${(props) => props.theme.colors.tfpBlack};
  border-radius: 5px;
  flex-grow: 1;
  margin-bottom: 3%;
`;

const FlexRow = styled.div`
  background-color: ${(props) => props.theme.colors.tfpDarkGrey};
  height: 3rem;
  border-radius: 5px 5px 0 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 5%;
  > * {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

const HelpHeader = styled.div`
  color: ${(props) => (props.highlighted ? props.theme.colors.tfpRed : props.theme.colors.tfpWhite)};
  font-size: 150%;
  font-weight: bold;
  cursor: pointer;
`;

/**
 * Applies the default table cell renderer to the given column definitions.
 * Does not override any existing cell renderers.
 */
function applyRendererToColumnDefs(defs) {
  return defs.map((def) => {
    if (!def.cellRenderer) {
      def.cellRenderer = (param) => {
        return <ConsoleHelpTableCell text={param.value} />;
      };
    }
    return def;
  });
}

const commandsColumnDefs = applyRendererToColumnDefs([
  {
    field: 'allowed',
    flex: 0.2,
    sort: 'desc',
    cellRenderer: (param) => {
      if (param.value) return '🟢';
      else return '🔴';
    },
    comparator: (a, b) => {
      return a - b;
    },
    cellStyle: { textAlign: 'center' },
  },
  { field: 'command', filter: 'agTextColumnFilter' },
  { field: 'description', filter: 'agTextColumnFilter' },
  { field: 'help', filter: 'agTextColumnFilter', flex: 2 },
]);

const playersColumnDefs = applyRendererToColumnDefs([
  { field: 'name', filter: 'agTextColumnFilter' },
  { field: 'platformId.combinedString', filter: 'agTextColumnFilter', headerName: 'Platform ID' },
  { field: 'crossplatformId.combinedString', filter: 'agTextColumnFilter', headerName: 'Crossplatform ID' },
  { field: 'ip', filter: 'agTextColumnFilter' },
  { field: 'entityId', filter: 'agTextColumnFilter' },
  { field: 'ping' },
  { field: 'lastOnline', type: ['date'] },
  { field: 'totalPlayTimeSeconds', filter: 'agNumberColumnFilter', headerName: 'Total play time' },
  { field: 'position.x', filter: 'agNumberColumnFilter', headerName: 'X' },
  { field: 'position.y', filter: 'agNumberColumnFilter', headerName: 'Y' },
  { field: 'position.z', filter: 'agNumberColumnFilter', headerName: 'Z' },
]);

const entityColumnsDef = applyRendererToColumnDefs([
  { field: 'id', filter: 'agNumberColumnFilter' },
  { field: 'name', filter: 'agTextColumnFilter', flex: 4 },
]);

const itemColumnsDef = applyRendererToColumnDefs([
  { field: 'name', filter: 'agTextColumnFilter', headerName: 'ID' },
  {
    field: 'isBlock',
    filter: 'agTextColumnFilter',
    headerName: 'Block',
    flex: 0.2,
    cellRenderer: (param) => {
      if (param.value) return '✅';
      else return '❎';
    },
    comparator: (a, b) => {
      return a - b;
    },
  },
  { field: 'localizedName', filter: 'agTextColumnFilter', headerName: 'Name' },
]);

const executedCommandsColumnsDef = applyRendererToColumnDefs([
  { field: 'timestamp', type: ['date'] },
  { field: 'input', filter: 'agTextColumnFilter' },
  { field: 'output', filter: 'agTextColumnFilter' },
]);

export function ConsoleHelp({ executedCommands }) {
  const [data, setData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(commandsColumnDefs);
  const [activeField, setActiveField] = useLocalStorageState('consoleHelpActiveField', {
    defaultValue: 'Commands',
  });

  function onComponentStateChanged({ columnApi }) {
    switch (activeField) {
      case 'Commands':
        setSorting(columnApi, 'command', 'asc');
        break;
      case 'Command history':
        setSorting(columnApi, 'timestamp', 'desc');
        break;
      case 'Players':
        setSorting(columnApi, 'name');
        break;
      case 'Entities':
        setSorting(columnApi, 'name');
        break;
      case 'Items':
        setSorting(columnApi, 'name');
        break;
      default:
        break;
    }
  }

  function setSorting(columnApi, column = 'name', order = 'asc') {
    columnApi.applyColumnState({
      state: [{ colId: column, sort: order }],
    });
  }

  useEffect(() => {
    async function fetchCommands() {
      try {
        const res = await HTTP.get('/api/command');
        setColumnDefs(commandsColumnDefs);
        setData(res.commands);
      } catch (error) {
        setColumnDefs(commandsColumnDefs);
        setData([]);
      }
    }

    async function fetchPlayers() {
      try {
        const players = await HTTP.getPlayers();
        setColumnDefs(playersColumnDefs);
        setData(players.map((p) => ({ ...p, lastonline: new Date(p.lastonline) })));
      } catch (error) {
        setColumnDefs(playersColumnDefs);
        setData([]);
      }
    }

    async function fetchEntities() {
      try {
        const res = await HTTP.executeCommand('spawnentity');
        const parsed = res.result
          .split('\n')
          .slice(3, -1)
          .map((entry) => {
            const split = entry.split('-').map((e) => e.trim());
            return { id: split[0], name: split[1] };
          });
        setColumnDefs(entityColumnsDef);
        setData(parsed);
      } catch (error) {
        setColumnDefs(entityColumnsDef);
        setData([]);
      }
    }

    async function fetchItems() {
      try {
        const res = await HTTP.getItems();
        setColumnDefs(itemColumnsDef);
        setData(res);
      } catch (error) {
        setColumnDefs(itemColumnsDef);
        setData([]);
      }
    }

    function fetchCommandHistory() {
      setData(executedCommands);
      setColumnDefs(executedCommandsColumnsDef);
    }

    switch (activeField) {
      case 'Commands':
        fetchCommands();
        break;
      case 'Command history':
        fetchCommandHistory();
        break;
      case 'Players':
        fetchPlayers();
        break;
      case 'Entities':
        fetchEntities();
        break;
      case 'Items':
        fetchItems();
        break;
      default:
        break;
    }
  }, [activeField, executedCommands]);

  const onClickHeader = (e) => {
    setActiveField(e.target.innerText);
  };

  return (
    <HelpWindow>
      <FlexRow>
        <HelpHeader onClick={onClickHeader} highlighted={activeField === 'Commands' ? true : false}>
          Commands
        </HelpHeader>
        <HelpHeader onClick={onClickHeader} highlighted={activeField === 'Command history' ? true : false}>
          Command history
        </HelpHeader>
        <HelpHeader onClick={onClickHeader} highlighted={activeField === 'Players' ? true : false}>
          Players
        </HelpHeader>
        <HelpHeader onClick={onClickHeader} highlighted={activeField === 'Items' ? true : false}>
          Items
        </HelpHeader>
        <HelpHeader onClick={onClickHeader} highlighted={activeField === 'Entities' ? true : false}>
          Entities
        </HelpHeader>
      </FlexRow>
      <Table
        rowData={data}
        columnDef={columnDefs}
        alwaysShowHorizontalScroll
        onComponentStateChanged={debounce(onComponentStateChanged, 100)}
      ></Table>
    </HelpWindow>
  );
}

ConsoleHelp.propTypes = {
  executedCommands: PropTypes.array,
};
