import React, { useEffect, useState } from 'react';
import { HTTP } from '../../lib/http';
import { EditableTable } from '../table';

export function UserPermissions() {
  const [rowData, setRowData] = useState([]);

  const columnDef = [
    { field: 'level', filter: 'agNumberColumnFilter', checkboxSelection: true },
    { field: 'ID', filter: 'agTextColumnFilter' },
    { field: 'name', filter: 'agTextColumnFilter' },
  ];

  async function getPermissions() {
    const admins = await HTTP.getAdminList();
    setRowData(admins);
  }

  useEffect(() => {
    getPermissions();
  }, []);

  function onGridReady({ columnApi }) {
    columnApi.applyColumnState({
      state: [{ colId: 'name', sort: 'asc' }],
    });
  }

  return (
    <>
      <EditableTable
        columnDef={columnDef}
        deleteRowFn={(row) => {
          HTTP.executeCommand(`admin remove ${row.ID}`);
        }}
        reloadFn={getPermissions}
        rowData={rowData}
        onGridReady={onGridReady}
      />
    </>
  );
}
