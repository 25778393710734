import React from 'react';
import { Accordion } from '../components/accordion';
import styled from 'styled-components/macro';
import { UserPermissions } from '../components/permissions/userPermissions';
import { CommandPermissions } from '../components/permissions/commandPermissions';
import { WebPermissions } from '../components/permissions/webPermissions';
import { WebTokens } from '../components/permissions/webTokens';
import { Bans } from '../components/permissions/bans';
import { hydrateModComponent, useMods } from '../lib/mods';
import { PERMISSIONS, useAuth } from '../components/auth';

const SettingsContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-y: scroll;
`;

export function Settings() {
  const { webMods } = useMods();
  const { checkPermission } = useAuth();

  const modSettings = [];

  if (webMods) {
    webMods.forEach((mod) => {
      for (const route in mod.external.settings) {
        if (Object.hasOwnProperty.call(mod.external.settings, route)) {
          const element = mod.external.settings[route];
          modSettings.push({
            title: route,
            component: hydrateModComponent(element, { checkPermission }),
          });
        }
      }
    });
  }

  const items = [];

  if (checkPermission({ module: PERMISSIONS['webapi.Command'], method: 'POST' })) {
    items.push({ title: 'User permissions', component: <UserPermissions /> });
    items.push({ title: 'Command permissions', component: <CommandPermissions /> });
    items.push({ title: 'Web permissions', component: <WebPermissions /> });
    items.push({ title: 'Web tokens', component: <WebTokens /> });
    items.push({ title: 'Bans', component: <Bans /> });
  }

  items.push(...modSettings);

  return (
    <SettingsContainer>
      <h1>Settings </h1>
      <Accordion items={items} />
    </SettingsContainer>
  );
}
