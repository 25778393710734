import React, { useEffect, useState } from 'react';
import { HTTP } from '../../lib/http';
import { EditableTable } from '../table';

export function Bans() {
  const [rowData, setRowData] = useState([]);

  const columnDef = [
    { field: 'date', type: ['date'], checkboxSelection: true },
    { field: 'ID', filter: 'agTextColumnFilter' },
    { field: 'name', filter: 'agTextColumnFilter' },
    { field: 'reason', filter: 'agTextColumnFilter' },
  ];

  async function getData() {
    const data = await HTTP.getBans();
    setRowData(data);
  }

  useEffect(() => {
    getData();
  }, []);

  function onGridReady({ columnApi }) {
    columnApi.applyColumnState({
      state: [{ colId: 'date', sort: 'asc' }],
    });
  }

  return (
    <>
      <EditableTable
        columnDef={columnDef}
        deleteRowFn={(row) => {
          HTTP.executeCommand(`ban remove ${row.ID}`);
        }}
        reloadFn={getData}
        rowData={rowData}
        onGridReady={onGridReady}
      />
    </>
  );
}
