import { AgGridReact } from 'ag-grid-react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { tableColumnTypes } from './types';

const caseInsensitiveComparator = (valueA, valueB) => {
  if (!valueA || !valueB) return 0;
  return String(valueA).toLowerCase().localeCompare(String(valueB).toLowerCase());
};

export const defaultColumDef = {
  flex: 1,
  wrapText: true,
  autoHeight: true,
  resizable: true,
  sortable: true,
  cellStyle: { textAlign: 'left' },
  comparator: caseInsensitiveComparator,
};

export function Table(userOptions = {}) {
  const defaultOptions = {
    theme: 'ag-theme-balham-dark',
    enableCellTextSelection: true,
    suppressCellFocus: true,
    enableRangeSelection: false,
  };
  const options = { ...defaultOptions, ...userOptions };

  const [, setGridApi] = useState(null);
  const [, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    if (userOptions.onGridReady) {
      userOptions.onGridReady(params);
    }
  };

  return (
    <div className={options.theme} style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        rowData={options.rowData}
        columnDefs={options.columnDef}
        defaultColDef={defaultColumDef}
        columnTypes={tableColumnTypes}
        alwaysShowHorizontalScroll
        onGridReady={onGridReady}
        {...options}
      ></AgGridReact>
    </div>
  );
}

Table.propTypes = {
  theme: PropTypes.string,
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnDef: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export function EditableTable(userOptions = {}) {
  const defaultOptions = {
    theme: 'ag-theme-balham-dark',
  };

  const options = { ...defaultOptions, ...userOptions };

  const onSuppressKeyboardEvent = (params) => {
    if (!params.editing) {
      let isBackspaceKey = params.event.keyCode === 8;
      let isDeleteKey = params.event.keyCode === 46;

      if (isBackspaceKey || isDeleteKey) {
        const selectedRows = params.api.getSelectedRows();

        if (options.deleteRowFn) {
          selectedRows.forEach((row) => {
            options.deleteRowFn(row);
          });
        } else {
          console.warn(`No deleteRowFn provided in table options, rows will not actually get deleted`);
        }

        options.reloadFn();

        return true;
      }

      return false;
    }
  };

  return (
    <Table
      rowData={options.rowData}
      columnDef={options.columnDef}
      defaultColDef={{ ...defaultColumDef, suppressKeyboardEvent: onSuppressKeyboardEvent }}
      onCellValueChanged={options.editRowFn}
      editType="fullRow"
      rowSelection="multiple"
      {...options}
    ></Table>
  );
}

EditableTable.propTypes = {
  theme: PropTypes.string,
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnDef: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteRowFn: PropTypes.func.isRequired,
  editRowFn: PropTypes.func.isRequired,
  reloadFn: PropTypes.func.isRequired,
};
